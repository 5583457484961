// Button.tsx
import React from "react";

const Spacer: React.FC = () => {
  // Default classes for the button's base styling
  // Combine base classes with any additional classes passed via props

  return <div className="border-b border-lightgrey w-4/5 mx-auto h-1 my-4" />;
};

export default Spacer;
