// Login.tsx
import React, { useState } from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import { useAPI } from "../../hooks/useAPI";
import GoogleButton from "../../components/GoogleButton";

const Login = () => {
  const { signInWithGoogle, signInWithEmailAndPassword, signingIn } = useAPI();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission
    signInWithEmailAndPassword(email, password);
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex justify-around w-full items-center bg-secondary flex-col md:flex-row h-full p-4">
        <form
          className="bg-white shadow-md rounded-2xl px-8 pt-6 pb-8 mb-4 w-full max-w-[640px] flex flex-col"
          onSubmit={handleSubmit}
        >
          <div>
            <img
              src={require("../../assets/logoLarge.png")}
              className="max-w-[300px] mx-auto rounded-full bg-white p-4"
              alt="Login Visual"
            />
          </div>
          <TextInput
            id="email"
            type="email"
            placeholder="Email"
            label="Email"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <TextInput
            id="password"
            type="password"
            placeholder="Password"
            label="Password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <div className="w-full flex items-center justify-center ml-auto">
            <Button
              loading={signingIn}

              type="submit"
            >
              Sign In
            </Button>
          </div>
          <Spacer />
        </form>
      </div>
    </div>
  );
};

export default Login;
