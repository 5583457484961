"use client";
import React, { useEffect, useState } from "react";
import "./Accordion.css"; // Import the CSS file
import { BsChevronRight } from "react-icons/bs";

interface AccordionProps {
  items: React.ReactNode[];
  headings: string[];
}

const Accordion: React.FC<AccordionProps> = ({ items, headings }) => {
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  const handleItemClicked = (index: number) => {
    setActiveIndices((prevActiveIndices) => {
      if (prevActiveIndices.includes(index)) {
        return prevActiveIndices.filter((i) => i !== index);
      } else {
        return [...prevActiveIndices, index];
      }
    });
  };

  useEffect(() => {
    console.log(activeIndices);
  }, [activeIndices]);

  return (
    <div className="w-full flex flex-col gap-2">
      {headings.map((heading, index) => (
        <div
          key={index}
          className={`${
            activeIndices.includes(index)
              ? `bg-lightgrey/40`
              : `bg-lightgrey hover:scale-[1.01] transition-all duration-50`
          } duration-50 overflow-hidden mt-2 rounded-xl `}
        >
          <div
            onClick={() => handleItemClicked(index)}
            className={`flex flex-row px-4 w-full my-2 items-center cursor-pointer ${
              activeIndices.includes(index) ? "text-darkgrey" : ""
            }`}
          >
            <BsChevronRight
              className={`chevron mt-0.5 ${activeIndices.includes(index) ? "open" : ""} mr-2`}
              size={15}
            />
            <h2 className={`${activeIndices.includes(index) ? "text-darkgrey" : ""}`}>{heading}</h2>
          </div>
          <div
            className={`accordion-content ${activeIndices.includes(index) ? "open text-white" : ""} overflow-x-scroll`}
          >
            {items[index]}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
