// components/LoadingIndicator.tsx
import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingIndicator = () => (
  // <div className={"w-full h-full bg-red fixed top-0 left-0 flex items-center justify-center"}>
  <div className={"text-red font-2xl h-full w-full min-h-[100px] flex items-center justify-center flex-col gap-2 p-10"}>
    <CircularProgress size={60} style={{ color: "#86eb85", marginBottom: "20px" }} />
    <p
      className="text-base
    "
    >
      Loading...
    </p>
  </div>
  // </div>
);

export default LoadingIndicator;
