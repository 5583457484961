// TextInput.tsx
import React from "react";

interface TextInputProps {
  id: string;
  type: string;
  placeholder?: string;
  label: string;
  value: string;
  onChange: any;
}

const TextInput: React.FC<TextInputProps> = ({ id, type, placeholder, label, value, onChange }) => {
  return (
    <div className="mb-4 font-montserrat">
      <label className="block text-gray-700 text-lg font-semibold mb-2 text-left " htmlFor={id}>
        {label}
      </label>
      <input
        className="border-b appearance-none  rounded w-full text-md py-2 px-3 text-gray-700 leading-tight focus:border-b-2 focus:shadow-outline"
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextInput;
