// Login.tsx
import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput"; // Make sure the path matches your file structure
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import Sidebar from "../../components/Sidebar";
import ResponsiveAppBar from "../../components/Navbar";
import Toolbar from "@mui/material/Toolbar";
import StatCard from "../../components/StatCard";
import { BarChart } from "../../components/charts/BarChart";
import { PieChart } from "../../components/charts/PieChart";
import { useUser } from "../../context/AppContext";
import { colors, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { DocusealForm } from "@docuseal/react";
import Backdrop from "@mui/material/Backdrop";
import DynamicTable, { renderContactItem, renderActiveIndicator } from "../../components/DynamicTable/DynamicTable";
import { useNavigate } from "react-router-dom";
import { mapDebitOrderStatus } from "../../util/util";

const Fulfillments = () => {
  const {
    getFulfillments,
    fulfillments,
    gettingFulfillments,
    setSelectedFulfillment
  } = useUser();
  const navigate = useNavigate();

  const [initialising, setInitialising] = useState(true);
  const [showContract, setShowContract] = useState(false);
  const [filteredData, setFilteredData] = useState<any>(fulfillments)
  useEffect(() => {
    if (!fulfillments) {
      setInitialising(false);
      getFulfillments();

    } else {
      console.log(fulfillments)
    }
  }, [fulfillments]);

  var titleTextStyle = {
    color: "#555", // You can set the title color
    fontName: "Montserrat",
    fontSize: 22, // Adjust as needed
    bold: true, // true or false
    italic: false, // true or false
  };

  var smallTitleTextStyle = {
    ...titleTextStyle,
    fontSize: 16, // Adjust as needed
  };

  const useFilteredList = (list: any) => {
    // Check if filtered list has actually changed before updating the state
    const newFilteredData = list?.map((l: any) => fulfillments.find((f: any) => f.fulfillmentID === l[0]));

    // Avoid updating the state if the new list is the same as the old list
    if (JSON.stringify(newFilteredData) !== JSON.stringify(filteredData)) {
      console.log("Updating filtered data");
      setFilteredData(newFilteredData);
    } else {
      console.log("Filtered data is the same, no update needed");
    }
  };

  const SummaryComponent = () => {
    // Calculations for the sum of different fields and other statistics
    console.log("Ths is the list: ", filteredData)
    if (!filteredData) return null

    const totalInvoicingAmount = filteredData.reduce((acc: any, item: any) => acc + (item.invoicingAmountPounds || 0), 0);
    const totalCompanyFee = filteredData.reduce((acc: any, item: any) => acc + (item.companyFeeAmount || 0), 0);
    const totalPartnerCommissionAmount = filteredData.reduce((acc: any, item: any) => acc + (item.partnerCommissionAmount || 0), 0);
    const itemCount = filteredData.length;
    const averageInvoicingAmount = itemCount > 0 ? (totalInvoicingAmount / itemCount).toFixed(2) : 0;




    const SummaryItem = ({ title, figure }: any) => {
      return <div className="flex flex-col items-center border-2 p-2 justify-center rounded-xl border-black ">
        <h2>{figure}</h2>
        <p className="text-center">{title}</p>
      </div>
    }

    return (
      <div className="summary-container">
        <div className="grid grid-cols-2 md:flex flex-row gap-2">
          {/* Other content within inner div */}

          {/* Add divs for the required summaries */}

          <SummaryItem title="Total Invoiced" figure={`£${totalInvoicingAmount.toFixed(2)}`} />

          <SummaryItem title="Company Fees" figure={`£${totalCompanyFee.toFixed(2)}`} />

          <SummaryItem title="Partner Commissions" figure={`£${totalPartnerCommissionAmount.toFixed(2)}`} />

          <SummaryItem title="Number of Items" figure={itemCount} />

          <SummaryItem title="Average Invoicing Amount" figure={`£${averageInvoicingAmount}`} />

        </div>
      </div>
    );
  };

  return (
    <div className="h-screen flex-col flex-grow bg-white ">
      <div className="flex-grow flex flex-col w-full h-full bg-white ">

        <div className="my-4 px-4 w-full flex flex-col gap-4">
          <h2>FULFILLMENTS</h2>
          <SummaryComponent />
          <DynamicTable
            tableId="FulfillmentsTable"
            cols={["FulfillmentID", "Application", "Fulf.#", "Company", "Date", "Product", "Value", "Invoice", "Sacrifice"]}
            filterableColumns={["Application", "Product", "Date", "Company", "Invoice", "Sacrifice"]}
            showExport={true}
            rows={fulfillments?.map((fulfillment: any) => {
              return [
                fulfillment.fulfillmentID,
                "***" + fulfillment.applicationID.split("_")[1],
                fulfillment.fulfillmentNumber,
                fulfillment.companyID,
                fulfillment.fulfilledDateTime,
                // renderActiveIndicator(fulfillment.active),
                // fulfillment.fulfillmentReference,
                fulfillment.benefitID,
                "£" + fulfillment.invoicingAmountPounds,
                !fulfillment.invoiced ? <div className="flex flex-grow h-full w-full items-center gap-2">
                  <div className="h-3 w-3 rounded-full bg-red"></div><p>None</p>
                </div> : <div className="flex flex-grow h-full w-full items-center gap-2">
                  <div className="h-3 w-3 rounded-full bg-green-500"></div><p className="text-xs">#{fulfillment.invoicingDetails.internalInvoiceID}</p>
                </div>,
                <div className="flex flex-grow h-full w-full items-center gap-2">

                  <div className={`h-3 w-3 rounded-full ${fulfillment.salarySacrificeDetails.status == "INPROGRESS" ? `bg-blue-500` : fulfillment.salarySacrificeDetails.status == "OUTSTANDING" ? `bg-red` : fulfillment.salarySacrificeDetails.status == "COMPLETED" ? `bg-green-600` : `bg-orange-500`}`}>
                  </div>
                  <p className="text-xs">{fulfillment.salarySacrificeDetails.status}</p>
                </div>
                // !fulfillment.sacrificed ? (
                //           <div className="flex flex-grow h-full w-full items-center gap-2">
                //             {(fulfillment.salarySacrificeDetails?.numberOfSalarySacrificesRequired > fulfillment.salarySacrificeDetails?.salarySacrificeCountRemaining) ?
                //               <>
                //                 <div className="h-3 w-3 rounded-full bg-blue-500">
                //                 </div>
                //                 <p>In Progress</p>
                //               </>
                //               : <>
                //                 <div className="h-3 w-3 rounded-full bg-red">
                //                 </div>
                //                 <p>None</p>
                //               </>
                //             }
                //           </div>)
                //           : (<div className="flex flex-grow h-full w-full items-center gap-2">
                //             <div className="h-3 w-3 rounded-full bg-green-500"></div> <p>Completed</p>
                //           </div>)

              ]
            })}
            onRowClicked={(fulfillmentID: any) => {

              const selected = fulfillments.find((c: any) => c.fulfillmentID == fulfillmentID);
              console.log(selected);
              setSelectedFulfillment(selected)
              // // localSt orage.setItem("selectedCompanyID", companies[index].companyID);
              // setSelectedCompany(selected);
              navigate(`/fulfillments/${fulfillmentID}`);
            }}
            idIndexInColumns={0}
            showID={false}
            // classNames={["", "", "", "", "", ""]}
            loading={gettingFulfillments}
            onFiltered={useFilteredList}
          />
        </div>
      </div>
    </div >
  );
};

export default Fulfillments;
