// Button.tsx
import { CircularProgress } from "@mui/material";
import React from "react";
import { useUser } from "../context/AppContext";

const ThinkingIndicator: React.FC = () => {
  // Default classes for the button's base styling
  // Combine base classes with any additional classes passed via props
  const {
    requestInProgress
  } = useUser();
  return requestInProgress && <div className="fixed top-20 right-5 h-16 w-16 rounded-full z-200 bg-white/80 shadow-lg flex flex-col items-center justify-center" >
    <CircularProgress size={40} color="info" />
  </div>;
};

export default ThinkingIndicator;
