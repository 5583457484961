// Login.tsx
import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput"; // Make sure the path matches your file structure
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import Sidebar from "../../components/Sidebar";
import ResponsiveAppBar from "../../components/Navbar";
import Toolbar from "@mui/material/Toolbar";
import StatCard from "../../components/StatCard";
import { BarChart } from "../../components/charts/BarChart";
import { PieChart } from "../../components/charts/PieChart";
import { useUser } from "../../context/AppContext";
import { colors, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { DocusealForm } from "@docuseal/react";
import Backdrop from "@mui/material/Backdrop";
import DynamicTable, { renderContactItem, renderActiveIndicator } from "../../components/DynamicTable/DynamicTable";
import { useNavigate, useParams } from "react-router-dom";
import { mapDebitOrderStatus } from "../../util/util";

const ViewFulfillment = () => {
  const routeParams = useParams();

  const {
    getFulfillments,
    fulfillments,
    gettingFulfillments,
    selectedFulfillment,
    setSelectedFulfillment
  } = useUser();
  const navigate = useNavigate();

  const [initialising, setInitialising] = useState(true);
  const [showContract, setShowContract] = useState(false);


  useEffect(() => {
    const fulfID = routeParams?.fulfillmentid;
    // console.log(fulfID, companies, selectedCompany);

    if (fulfillments && !selectedFulfillment && fulfID) {
      console.log("setting selected fulfillment to " + fulfID);
      setSelectedFulfillment(fulfillments.find((c: any) => c.fulfillmentID == fulfID));
    }
  }, [fulfillments]);

  useEffect(() => {
    const fulfID = routeParams?.fulfillmentid;
    // console.log(fulfID, companies, selectedCompany);

    if (selectedFulfillment) {
      console.log("selected fulfillment: ", selectedFulfillment);
      // setSelectedFulfillment(fulfillments.find((c: any) => c.fulfillmentID == fulfID));
    }
  }, [selectedFulfillment]);

  useEffect(() => {
    if (!fulfillments) {
      setInitialising(false);
      getFulfillments();

    } else {
      console.log(fulfillments)
    }
    window.scrollTo({ top: 0 })
  }, []);

  var titleTextStyle = {
    color: "#555", // You can set the title color
    fontName: "Montserrat",
    fontSize: 22, // Adjust as needed
    bold: true, // true or false
    italic: false, // true or false
  };

  var smallTitleTextStyle = {
    ...titleTextStyle,
    fontSize: 16, // Adjust as needed
  };
  return (
    <div className="h-screen flex-col flex-grow bg-white ">
      <ResponsiveAppBar />
      <div className="flex-grow flex w-full h-full pt-1 bg-white ">

        {selectedFulfillment &&
          <div className="m-10 w-full flex flex-col  gap-5">
            <div className=" flex flex-col md:flex-row justify-between md:gap-4 ">
              <div className=" flex flex-col justify-between ">
                <h1>Fulfillment #{selectedFulfillment.fulfillmentNumber} for {selectedFulfillment.applicationID} </h1>
                <h4>ID: {selectedFulfillment.fulfillmentID} </h4>
                <a href={`/companies/${selectedFulfillment.companyID}/application/${selectedFulfillment.applicationID}`}>View Application</a>
              </div>
              <div className="flex flex-col gap-2">
                {/* <img src={selectedApplication?.benefit.imageURL} className="w-12 h-12 rounded-full" /> */}
                <p className="text-lg">Fulfilled on <strong>{DateTime.fromISO(selectedFulfillment.fulfilledDateTime).toFormat("dd MMM yyyy hh:mm")} </strong></p>
                <p className="text-lg">Product: <strong>{selectedFulfillment.productDescription} </strong></p>
                <p className="text-lg">Product ID: <strong>{selectedFulfillment.benefitID} </strong></p>


              </div>
            </div>
            <div className=" flex flex-col justify-between md:gap-4">
              <h2>Invoicing Information</h2>
              <div className="border-2 rounded-lg p-4">
                {selectedFulfillment.invoiced ?
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-col items-end">
                      <p className="text-lg">Internal Invoice Reference: </p>
                      <p className="text-lg">Xero Invoice Reference: </p>
                      <p className="text-lg">Amount invoiced to client:</p>
                    </div>
                    <div>
                      <p className="text-lg"> <strong>{selectedFulfillment.invoicingDetails.internalInvoiceID}</strong></p>
                      <a
                        href={`https://go.xero.com/app/!0!9hs/invoicing/view/${selectedFulfillment.invoicingDetails.xeroInvoiceID}`}
                        target="_blank"
                        className="flex flex-row items-start justify-start gap-1 bg-white py-1 rounded-md"
                      >
                        <p className="text-lg"> <strong>{selectedFulfillment.invoicingDetails.xeroInvoiceNumber}</strong></p>
                        <img src={require("../../assets/openInNewTab.png")} className="w-6 h-6" />
                      </a>
                      <p className="text-lg"> <strong>£{selectedFulfillment.totalFinalAmount} </strong></p>

                    </div>
                  </div>
                  :
                  <div className="flex flex-row items-center gap-2">
                    <div className={`h-3 w-3 rounded-full bg-red`}></div><p>This fulfillment has not been invoiced yet</p></div>
                }
              </div>
              {/* <div className=" flex flex-col md:flex-row justify-between md:gap-4">

                <div className=" flex flex-col justify-between ">
                  <h2>Fulfillment #{selectedFulfillment.fulfillmentNumber} for {selectedFulfillment.applicationID} </h2>
                  <h4>ID: {selectedFulfillment.fulfillmentID} </h4>

                </div>
                <div className="flex flex-col gap-2">
              <p className="text-lg">Fulfilled on <strong>{DateTime.fromISO(selectedFulfillment.fulfilledDateTime).toFormat("dd MMM yyyy hh:mm")} </strong></p>
              <p className="text-lg">Product: <strong>{selectedFulfillment.productDescription} </strong></p>
              <p className="text-lg">Product ID: <strong>{selectedFulfillment.benefitID} </strong></p>


            </div>


          </div> */}
            </div>
            <div className=" flex flex-col justify-between md:gap-4">
              <h2>Salary Sacrifice History</h2>
              <div className="flex flex-col items-start">
                <p className="text-lg">Start Date of Sacrifices: {DateTime.fromISO(selectedFulfillment.salarySacrificeDetails.startDateOfSacrifice).toISODate()} </p>
                <p className="text-lg">End Date of Sacrifices: {DateTime.fromISO(selectedFulfillment.salarySacrificeDetails.endDateOfSacrifice).toISODate()} </p>
              </div>
              <div className="border-2 rounded-lg p-4">
                {selectedFulfillment.sacrificesCompleted ?
                  <div className="flex flex-col gap-2 w-full">

                    <DynamicTable
                      tableId="SalSacTable"
                      cols={["Report ID", "Amount", "Report Issued"]}
                      showExport={true}

                      rows={selectedFulfillment.sacrificesCompleted?.map((sac: any) => {
                        return [
                          sac.reportID,
                          `£${sac.amountSacrificed.toFixed(2)}`,
                          DateTime.fromISO(sac.completionDateTime).toFormat("yyyy-MM-dd"),
                        ]
                      })}
                      onRowClicked={(fulfillmentID: any) => {
                        const selected = fulfillments.find((c: any) => c.fulfillmentID == fulfillmentID);
                        console.log(selected);
                        setSelectedFulfillment(selected)
                        navigate(`/fulfillments/${fulfillmentID}`);
                      }}
                      idIndexInColumns={0}
                      showID={true}
                      loading={gettingFulfillments}
                    />
                    {/* )
                    })} */}

                  </div>
                  :
                  <div className="flex flex-row items-center gap-2">
                    <div className={`h-3 w-3 rounded-full bg-red`}></div>
                    <p>This fulfillment has not been sacrificed yet</p>
                  </div>
                }
              </div>
              {/* <div className=" flex flex-col md:flex-row justify-between md:gap-4">

                <div className=" flex flex-col justify-between ">
                  <h2>Fulfillment #{selectedFulfillment.fulfillmentNumber} for {selectedFulfillment.applicationID} </h2>
                  <h4>ID: {selectedFulfillment.fulfillmentID} </h4>

                </div>
                <div className="flex flex-col gap-2">
              <p className="text-lg">Fulfilled on <strong>{DateTime.fromISO(selectedFulfillment.fulfilledDateTime).toFormat("dd MMM yyyy hh:mm")} </strong></p>
              <p className="text-lg">Product: <strong>{selectedFulfillment.productDescription} </strong></p>
              <p className="text-lg">Product ID: <strong>{selectedFulfillment.benefitID} </strong></p>


            </div>


          </div> */}
            </div>
            {/*   <DynamicTable
            tableId="FulfillmentsTable"
            cols={["Application", "Fulf.#", "Date", "Ref", "Product", "Value", "Invoice", "Sacrificed"]}
            filterableColumns={["Application", "Benefit", "Invoice", "Sacrificed"]}
            showExport={true}
            rows={fulfillments?.map((fulfillment: any) => {
              return [
                fulfillment.applicationID,
                fulfillment.fulfillmentNumber,
                DateTime.fromISO(fulfillment.fulfilledDateTime).toFormat("yyyy-MM-dd HH:mm"),
                // renderActiveIndicator(fulfillment.active),
                fulfillment.fulfillmentReference,
                fulfillment.benefitID,
                "£" + fulfillment.invoicingAmountPounds,
                !fulfillment.invoiced ? <div className="flex flex-grow h-full w-full items-center gap-2">
                  <div className="h-3 w-3 rounded-full bg-red"></div><p>None</p>
                </div> : <div className="flex flex-grow h-full w-full items-center gap-2">
                  <div className="h-3 w-3 rounded-full bg-green-500"></div><p>#{fulfillment.invoicingDetails.internalInvoiceID}</p>
                </div>,
                !fulfillment.sacrificed ? (
                  <div className="flex flex-grow h-full w-full items-center gap-2">
                    {(fulfillment.salarySacrificeDetails?.numberOfSalarySacrificesRequired > fulfillment.salarySacrificeDetails?.salarySacrificeCountRemaining) ?
                      <>
                        <div className="h-3 w-3 rounded-full bg-blue-500">
                        </div>
                        <p>In Progress</p>
                      </>
                      : <>
                        <div className="h-3 w-3 rounded-full bg-red">
                        </div>
                        <p>None</p>
                      </>
                    }
                  </div>)
                  : (<div className="flex flex-grow h-full w-full items-center gap-2">
                    <div className="h-3 w-3 rounded-full bg-green-500"></div> <p>Completed</p>
                  </div>)
              ]
            })}
            onRowClicked={(companyID: any) => {
              // const selected = companies.find((c: any) => c.companyID == companyID);
              // console.log(selected);
              // // localStorage.setItem("selectedCompanyID", companies[index].companyID);
              // setSelectedCompany(selected);
              // navigate(`/companies/${companyID}`);
            }}
            idIndexInColumns={0}
            showID={true}
            // classNames={["", "", "", "", "", ""]}
            loading={gettingFulfillments}
          />*/}
          </div>
        }
      </div >
    </div >
  );
};

export default ViewFulfillment;
