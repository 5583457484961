// import logo from './logo.svg';
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Layout from "./components/Layout/Layout";

import AllApplications from "./screens/AllApplications/AllApplications";
import { UserProvider, useUser } from "./context/AppContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewApplication from "./screens/ViewApplication/ViewApplication";
import Companies from "./screens/Companies/Companies";
import Application from "./screens/Application/Application";
import ThinkingIndicator from "./components/ThinkingIndicator";
import React from "react";
import Fulfillments from "./screens/Fulfillments/Fulfillments";
import ViewFulfillment from "./screens/ViewFulfillment/ViewFulfillment";
import CompanyPage from "./screens/CompanyPage/CompanyPage";
import Login from "./screens/Login/Login";

function App() {
  const { currentUser } = useUser();
  return (
    <BrowserRouter>
      <UserProvider>

        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />

            <Route path="/applications" element={<AllApplications />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/fulfillments" element={<Fulfillments />} />
            <Route path="/viewApplication" element={<ViewApplication />} />
            <Route path="/fulfillments/:fulfillmentid" element={<ViewFulfillment />} />
            <Route path="/companies/:companyid" element={<CompanyPage />} />
            <Route path="/companies/:companyid/application/:applicationid" element={<Application />} />
          </Route>
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          limit={5}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <React.Fragment>
          <ThinkingIndicator />
        </React.Fragment>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
