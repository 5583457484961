// Button.tsx
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  loading: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className, type = "button", loading }) => {
  // Default classes for the button's base styling
  const baseClasses =
    "bg-primary hover:bg-secondary font-montserrat text-xl w-full mt-2 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-200 hover:text-primary";

  // Combine base classes with any additional classes passed via props
  const buttonClasses = `${baseClasses} ${className || ""}`;

  return (
    <button
      onClick={onClick}
      className={buttonClasses}
      type={type}
      disabled={loading}
    >
      {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : children}
    </button>
  );
};

export default Button;
