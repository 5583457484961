// src/hooks/useAPI.js

import { useCallback, useEffect, useState } from "react";
import { auth } from "../firebaseConfig";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Make sure you've installed react-router-dom
import {
  onAuthStateChanged,
  getAuth,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { useUser } from "../context/AppContext";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { ContactPageOutlined } from "@mui/icons-material";

export const useAPI = () => {

  const navigate = useNavigate();
  const [dashboardError, setAllApplicationsError] = useState(false);
  const [companiesError, setCompaniesError] = useState(false);
  const [companies, setCompanies] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [allApplications, setAllApplicationsData] = useState<any>();
  const [runningAllApplicationsQuery, setRunningAllApplicationsQuery] = useState(true);
  const [runningApplicationExportQuery, setRunningApplicationExportQuery] = useState(false);
  const [runningInvoicingQuery, setRunningInvoicingQuery] = useState(false);

  const [approvingApplication, setApprovingApplication] = useState(false);

  const [signingIn, setSigningIn] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<any>();
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [gettingCompanies, setGettingCompanies] = useState<any>();

  const [gettingCompanyDetails, setGettingCompanyDetails] = useState<any>();
  const [companyDetails, setCompanyDetails] = useState<any>();
  const [companyApplications, setCompanyApplications] = useState<any>();
  const [companyPendingInvoiceItems, setCompanyPendingInvoiceItems] = useState<any>();
  const [companySalarySacrificeRecurringItems, setCompanySalarySacrificeRecurringItems] = useState<any>();
  const [companyXeroConfiguration, setCompanyXeroConfiguration] = useState<any>();

  const [dashboardFilterStartDate, setAllApplicationsFilterStartDate] = useState<DateTime | null>(null);
  const [dashboardFilterEndDate, setAllApplicationsFilterEndDate] = useState<DateTime | null>(null);
  const [logoutInProgress, setLogoutInProgress] = useState(false); // New flag for logout state
  const [companiesTemp, setCompaniesTemp] = useState<any>(); // New flag for logout state

  const [requestInProgress, setRequestInProgress] = useState(false);
  const dashboardApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/dashboard";
  const invoicingApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/invoice";
  const userApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/user";
  const sftpApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/sftp";
  const salarySacrificeApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/salary-sacrifice";
  const applicationApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/application";
  const voucherApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/voucher";
  const paymentApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/payment";
  let activeRequests = 0

  let tokenCheckPromise: Promise<unknown> | null = null;

  useEffect(() => {

    console.log("Setting up the interceptors")
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        activeRequests += 1
        setRequestInProgress(true)
        try {
          console.log(config.url)
          if (config.url?.includes("authenticate")) {
            // Bypass the token check and proceed with the request
            return config;
          }

          await checkTokenAndProceed(); // Wait for token check to complete
          const token = localStorage.getItem('sessionToken');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        } catch (error) {
          activeRequests -= 1
          if (activeRequests <= 0) {
            setRequestInProgress(false)
            activeRequests = 0
          }
          return Promise.reject(error);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // Set up Axios interceptor
    const responseInterceptor = axios.interceptors.response.use(
      response => {
        activeRequests -= 1
        if (activeRequests <= 0) {
          setRequestInProgress(false)
          activeRequests = 0
        }
        return response;
      },
      error => {
        activeRequests -= 1
        if (activeRequests <= 0) {
          setRequestInProgress(false)
          activeRequests = 0
        }
        if (
          error.response?.status === 401 &&
          (error.response?.data?.message === "EXPIRED" || error.response?.data?.message === "INVALID")
        ) {
          console.log(" IN THE INTERCEPTOR: " + error.response + " " + logoutInProgress
          )
          if (!logoutInProgress) {
            axios.defaults.headers.common["Authorization"] = "";

            handleLogout(); // Call the logout function if not already in progress
          }
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    const token = localStorage.getItem("sessionToken");
    const localUser = localStorage.getItem("userData");

    if (token) {
      getAllApplicationsData();
      getCompanies();
    }

    if (localUser) {
      setCurrentUser(JSON.parse(localUser));
    }

    return () => {
      axios.interceptors.request.eject(requestInterceptor); // Clean up the interceptor when the component unmounts

      axios.interceptors.response.eject(responseInterceptor); // Clean up the interceptor when the component unmounts
    };
  }, []);

  useEffect(() => {
    console.log("Running requests: ", requestInProgress)
  }, [requestInProgress])

  useEffect(() => {
    if (selectedCompany) {
      getCompanyDetails(selectedCompany.companyID, { startDate: dashboardFilterStartDate, endDate: dashboardFilterEndDate })
    }
  }, [selectedCompany])

  // useEffect(() => {
  //   if (currentUser && (window.location.pathname === '/' || window.location.pathname === '/login')) {
  //     navigate('/applications');
  //   }
  // }, [currentUser, navigate]);

  const checkTokenAndProceed = async () => {
    if (tokenCheckPromise) {
      console.log("Check already in progress - cancelling this one")
      return tokenCheckPromise; // If a check is already in progress, return the same promise
    }

    tokenCheckPromise = new Promise(async (resolve, reject) => {

      if (isTokenExpired()) {

        signOut();
        reject(new Error('Token expired'));
      } else {
        resolve(null); // Token is valid, proceed with requests
      }
    });

    try {
      await tokenCheckPromise;
    } finally {
      tokenCheckPromise = null; // Reset after the check is complete
    }
  };

  const isTokenExpired = () => {
    console.log("Checking if token is expired")

    const token = localStorage.getItem('sessionToken');
    if (!token) return true;
    console.log("Checking if token is expired2")

    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const expiryDate = DateTime.fromSeconds(tokenPayload.exp);
      return expiryDate < DateTime.now();
    } catch (e) {
      console.error("Failed to parse token:", e);
      return true;
    }
  };

  const handleLogout = () => {
    setCurrentUser(null);

    setLogoutInProgress(true);
    firebaseSignOut(auth)
      .then(() => {
        toast("You have been signed out!", { type: "info" });
        localStorage.removeItem("sessionToken");
        setCurrentUser(null);
        setSigningIn(false);
        setRunningAllApplicationsQuery(false);
        navigate("/login");
      })
      .catch(error => {
        console.error("Error signing out: ", error);
      })
      .finally(() => {
        console.log("Delaying set logout in progress")
        setTimeout(() => {
          console.log("Changing set logout in progress")
          setLogoutInProgress(false);

        }, 5000)
      });
  };

  const getSessionToken = async (user: any) => {
    if (user) {
      setSigningIn(true);
      const token = await auth.currentUser?.getIdToken();

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      console.log("Calling sign in scheme user")
      console.log(`URL: ${dashboardApiUrl}/authenticate`)
      axios.get(`${dashboardApiUrl}/authenticate`)
        .then((response) => {

          console.log("here")
          setSigningIn(false);
          localStorage.setItem("sessionToken", response.data.sessionToken);
          setCurrentUser(response.data.data);
          console.log("Layout - this is the current user", response.data.data)
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          navigate("/applications");
        })
        .catch((error) => {
          console.log(error)
          toast("We couldn't sign you in! Please try again later.", { type: "error" });
          setSigningIn(false);

          handleLogout();
        });
    } else {
      navigate("/login");
      handleLogout();
    }
  };

  const updateApplicationCountPerCompany = async () => {
    console.log("Updating app counts...")
    console.log("Applications: " + allApplications?.length)
    console.log("Companies: " + companiesTemp?.length)
    // Initialize the map for company counts during the loop over all applications
    let companyMap = new Map();

    // Loop through all applications and count how many are linked to each companyID
    allApplications?.forEach((application: any) => {
      const companyId = application.companyID;
      companyMap.set(companyId, (companyMap.get(companyId) || 0) + 1);

    });
    console.log(companyMap)
    // Loop through allCompanies and update the countOfApplications directly from the map
    companiesTemp?.forEach((company: any) => {
      company.countOfApplications = companyMap.get(company.companyID) || 0;
    });

    setCompanies(companiesTemp)
  }

  useEffect(() => {
    if (allApplications && companiesTemp) {
      updateApplicationCountPerCompany()
    }
  }, [allApplications, companiesTemp])

  const getAllApplicationsData = async () => {
    setRunningAllApplicationsQuery(true);
    axios.post(`${dashboardApiUrl}/applications`, {
      startDate: DateTime.now().minus({ months: 1 }).toFormat("yyyy-MM-dd"),
      endDate: DateTime.now().toFormat("yyyy-MM-dd"),
    },
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("sessionToken") },
      }
    )
      .then((response) => {
        setAllApplicationsData(response.data.data);
        setRunningAllApplicationsQuery(false);
        console.log(response.data.data)
        if (selectedApplication) {
          const app = response.data.data.find((a: any) => a.id == selectedApplication.id);
          setSelectedApplication(app);
        }
      })
      .catch((error) => {
        setRunningAllApplicationsQuery(false);
        console.log(error.response?.data.message);
      });
  };

  const getCompanies = async (filters?: any) => {
    setGettingCompanies(true);
    axios.post(`${dashboardApiUrl}/companies`, filters, {
      headers: { Authorization: "Bearer " + localStorage.getItem("sessionToken") },
    })
      .then((response) => {
        console.log(response.data.data)
        // setCompanies(response.data.data);
        setCompaniesTemp(response.data.data)
        setGettingCompanies(false);
      })
      .catch((error) => {
        setGettingCompanies(false);
        console.log(error.response?.data.message);
      });
  };

  // const getSessionToken = async (user: any) => {
  //   if (user) {
  //     setSigningIn(true);

  //     // Call your API endpoint after successful sign-in
  //   //     const token = await auth.currentUser?.getIdToken();
  //     axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  //     axios
  //       .get(`${dashboardApiUrl}/signInSchemeUser`)
  //       .then((response) => {
  //         // Store the data in state
  //         setSigningIn(false);
  //         console.log("THIS IS THE SESSION TOKEN" + response.data.sessionToken);
  //         localStorage.setItem("sessionToken", response.data.sessionToken);
  //         setCurrentUser(response.data.data);
  //         localStorage.setItem("userData", JSON.stringify(response.data.data));
  //         console.log(response.data);
  //         navigate("/applications");
  //       })
  //       .catch((error) => {
  //         // If there's an error, sign the user out
  //         console.error("There was an error fetching user data:", error);
  //         toast(`We couldn't sign you in! Please try again later.`, { type: "error" });
  //         resetState();
  //       });
  //   } else {
  //     console.log("User is signed out");

  //     navigate("/login");
  //     resetState();
  //   }
  // };

  const resetState = () => {
    setCurrentUser(null);
    setAllApplicationsData(null);
    setSigningIn(false);
    setRunningAllApplicationsQuery(false);
  };

  const signInWithGoogle = useCallback(() => {
    setSigningIn(true);

    const provider = new GoogleAuthProvider();
    console.log("Signing in...");
    signInWithPopup(auth, provider)
      .then((result) => {

        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;

        // The signed-in user info.
        const user = result.user;
        console.log(user);
        console.log("HERE");
        getSessionToken(user);
        // Update app state or UI with user info
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error("SignIn with Google error:", errorMessage);
        // Update UI to show error message
      });
  }, []);

  const signInWithEmailAndPassword = (email: string, password: string) => {
    setSigningIn(true);

    firebaseSignInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        console.log("Got user credential")
        const user = userCredential.user;
        console.log(user);
        getSessionToken(user);

        // You can redirect the user to the dashboard or do other things here
      })
      .catch((error) => {
        setSigningIn(false);
        toast("Login failed.", { type: "error" });
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        // Handle errors here, such as showing a notification to the user
      });
  };

  const signOut = () => {
    firebaseSignOut(auth)
      .then(() => {
        // Sign-out successful.
        // Redirect to login page or handle the sign out UI here
        console.log("User signed out successfully");
        toast("You have been signed out!", { type: "info" });
        localStorage.removeItem("sessionToken");
        resetState();
        navigate("/login");
      })
      .catch((error) => {
        // An error happened during sign out.
        console.error("Error signing out: ", error);
      });
  };

  const viewApplication = (app: any) => {
    setSelectedApplication(app);
    const comp = companies?.find((c: any) => c.companyID == app.company.companyID);
    setSelectedCompany(comp);
    localStorage.setItem("selectedApplication", JSON.stringify(app));
    navigate(`/companies/${app.company.companyID}/application/${app.id}`);
  };


  const getApplicationsExport = async (type: string) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningApplicationExportQuery(true);
    axios
      .post(
        `${dashboardApiUrl}/applications-export`,
        {
          type: type,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
          responseType: "blob", // Important for handling binary data
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Applications_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`); // or any other extension
        document.body.appendChild(link);
        link.click();
        // setAllApplicationsData(response.data.data);
        setRunningApplicationExportQuery(false);
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningApplicationExportQuery(false);
        console.log(error.response?.data.message);

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };


  const getMarketingContactsExport = async (type: string) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningApplicationExportQuery(true);
    axios
      .post(
        `${userApiUrl}/get-marketing-contacts`,
        {
          type: type,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
          responseType: "blob", // Important for handling binary data
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `AllContacts_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`); // or any other extension
        document.body.appendChild(link);
        link.click();
        // setAllApplicationsData(response.data.data);
        setRunningApplicationExportQuery(false);
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningApplicationExportQuery(false);
        console.log(error.response?.data.message);

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };


  const getSFTPFileForCompany = async () => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningApplicationExportQuery(true);
    axios
      .post(
        `${sftpApiUrl}/download`,
        {
          companyID: selectedCompany.companyID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
          responseType: "blob", // Important for handling binary data
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedCompany.companyID}_sftpexport_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.zip`); // or any other extension
        document.body.appendChild(link);
        link.click();
        // setAllApplicationsData(response.data.data);
        setRunningApplicationExportQuery(false);
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningApplicationExportQuery(false);
        console.log(error.response?.data.message);

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };

  const getActivationsLogHistory = async (type: string) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningApplicationExportQuery(true);
    axios
      .post(
        `${dashboardApiUrl}/activations-log-export`,
        {
          startDate: dashboardFilterStartDate?.toFormat("yyyy-MM-dd"),
          endDate: dashboardFilterEndDate?.toFormat("yyyy-MM-dd"),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
          responseType: "blob", // Important for handling binary data
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Activations_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`); // or any other extension
        document.body.appendChild(link);
        link.click();
        // setAllApplicationsData(response.data.data);
        setRunningApplicationExportQuery(false);
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningApplicationExportQuery(false);
        console.log(error.response?.data.message);
        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };

  const getCurrentSalarySacrificeReport = async (companyID: string, month?: string, year?: string) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningApplicationExportQuery(true);
    axios
      .post(
        `${salarySacrificeApiUrl}/current`,
        {
          companyID: companyID,
          month: month ?? null,
          year: year ?? null,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
          responseType: "blob", // Important for handling binary data
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data);
        if (response.data.success == false) {
          toast(response.data.message)
          return;
        }
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          month && year
            ? `${companyID}_SALSAC_${month.toUpperCase()}_${year}.csv`
            : `${companyID}_SALSAC_${DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss")}.csv`
        ); // or any other extension
        document.body.appendChild(link);
        link.click();

        // setAllApplicationsData(response.data.data);
        setRunningApplicationExportQuery(false);
        getCompanyDetails(companyID)
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningApplicationExportQuery(false);
        console.log(error)
        console.log(error.response?.data.message);
        toast("A report has already been issued for this month.")

        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };

  const sendSpecificSalarySacrificeReport = async (companyID: string, month?: string, year?: string) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningApplicationExportQuery(true);
    axios
      .post(
        `${salarySacrificeApiUrl}/send`,
        {
          companyID: companyID,
          month: month ?? null,
          year: year ?? null,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data);
        if (response.data.success == false) {
          toast.error(response.data.message)
        } else {
          toast.success(response.data.message)
        }
        // Create a URL for the blob

        setRunningApplicationExportQuery(false);
        getCompanyDetails(companyID)

      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningApplicationExportQuery(false);
        console.log(error)
        console.log(error.response?.data.message);
        toast("Something went wrong while sending the report.")

        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };


  const getPastSalarySacrificeReport = async (companyID: string, reportID: string) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningApplicationExportQuery(true);
    axios
      .post(
        `${salarySacrificeApiUrl}/past`,
        {
          companyID: companyID,
          reportID: reportID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
          responseType: "blob", // Important for handling binary data
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedCompany.name} Salary Sacrifice Report ${reportID}.csv`); // or any other extension
        document.body.appendChild(link);
        link.click();
        // setAllApplicationsData(response.data.data);
        setRunningApplicationExportQuery(false);
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningApplicationExportQuery(false);
        console.log(error.response?.data.message);
        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };

  const cancelApplication = async (applicationID: string) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    if (!selectedApplication) {
      console.log("Can only perform this function when a application is being viewed.");
      return;
    }
    setRunningAllApplicationsQuery(true);
    return axios
      .post(
        `${applicationApiUrl}/cancel`,
        {
          applicationID: applicationID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )

      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningAllApplicationsQuery(false);
        console.log(error.response?.data.message);
        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
          return Promise.reject();
        }
        return Promise.reject();
      });
  };

  const resendNewApplicationEmails = async () => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    if (!selectedApplication) {
      console.log("Can only perform this function when a application is being viewed.");
      return;
    }
    setRunningAllApplicationsQuery(true);
    return axios
      .post(
        `${dashboardApiUrl}/resend-new-application-emails`,
        {
          applicationID: selectedApplication?.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        toast.success("Application Emails resent successfully!");
        setRunningAllApplicationsQuery(false);
        return Promise.resolve();
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningAllApplicationsQuery(false);
        console.log(error.response?.data.message);
        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
          return Promise.reject();
        }
        return Promise.reject();
      });
  };

  const resendHRApprovalEmail = async () => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    if (!selectedApplication) {
      console.log("Can only perform this function when a application is being viewed.");
      return;
    }
    setRunningAllApplicationsQuery(true);
    axios
      .post(
        `${dashboardApiUrl}/resend-hr-approval-email`,
        {
          applicationID: selectedApplication?.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        setSelectedApplication(response.data.data);
        toast.success("New application email sent to the company successfully.");
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setRunningAllApplicationsQuery(false);
        console.log(error.response?.data.message);
        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };


  const renewApplication = async () => {
    // Call your API endpoint after successful sign-in
    if (!selectedApplication) {
      console.log("Can only perform this function when a application is being viewed.");
      return;
    }
    const confirmed = window.confirm(
      selectedApplication?.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ?
        `This will send out a voucher to ${selectedApplication?.employee.firstName + ` ` + selectedApplication.employee.surname}. Are you sure you want to continue?`
        : `This will mark the application as COMPLETED. Are you sure you want to continue?`
    );
    if (confirmed) {
      // Perform the action here

      console.log("Action confirmed and performed.");

      // const token = await auth.currentUser?.getIdToken();

      setRunningAllApplicationsQuery(true);
      axios
        .post(
          `${voucherApiUrl}/renew-application`,
          {
            applicationID: selectedApplication?.id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("sessionToken"),
            },
          }
        )
        .then((response) => {
          // Store the data in state
          console.log(response.data.data);
          setSelectedApplication(response.data.data);
          getAllApplicationsData()
          toast.success(selectedApplication?.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ?
            `The voucher was sent!`
            : `The application has been marked as completed!`);

        })
        .catch((error) => {
          // If there's an error, sign the user out
          setAllApplicationsError(true);
          setRunningAllApplicationsQuery(false);
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";

          if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
            signOut();
            localStorage.removeItem("sessionToken");
          }
        });
    } else {
      // Action was canceled
      console.log("Action canceled.");
    }
  };

  /**
   * 
   * @param app 
   * THIS LOOKS LIKE IT'S NOT BEING USED ANYMORE
   * MUST BE COPIED FROM THE FRONTEND APP
   */
  const approveApplication = async (app: any) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setApprovingApplication(true);
    axios
      .post(
        `${dashboardApiUrl}/approveApplication`,
        {
          companyID: app.company.companyID,
          applicationID: app.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        toast("Approved!");
        setAllApplicationsData(response.data.data.reverse());
        setApprovingApplication(false);
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setApprovingApplication(false);
        console.log(error.response?.data.message);
        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };

  const addCompanyActivityHistory = async (activityItem: any) => {
    // Call your API endpoint after successful sign-in
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setApprovingApplication(true);
    axios
      .post(
        `${dashboardApiUrl}/add-company-activity-log`,
        {
          companyID: selectedCompany.companyID,
          activityLog: activityItem
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response.data.data);
        toast("Note created.");
        setSelectedCompany(response.data.company)
        setCompanies(companies.map((c: any) => {
          if (c.companyID == selectedCompany.companyID) {
            return response.data.company
          } else return c
        }))
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setAllApplicationsError(true);
        setApprovingApplication(false);
        console.log(error.response?.data.message);
        axios.defaults.headers.common["Authorization"] = "";

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          signOut();
          localStorage.removeItem("sessionToken");
        }
      });
  };

  const getCompanyDetails = async (companyID: any, filters?: any) => {
    // Call your API endpoint after successful sign-in
    setCompanyDetails(null);
    setCompanyApplications(null);
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setGettingCompanyDetails(true);
    console.log("THESE ARE THE FILTERS:", filters);
    axios
      .post(
        `${dashboardApiUrl}/company-details`,
        {
          companyID: companyID,
          filters: { dates: filters },
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response);
        setCompanyDetailsFromResponse(response);
        setGettingCompanies(false);
      })
      .catch((error) => {
        // If there's an error, sign the user out
        setGettingCompanies(false);

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        } else {
          toast.error("Something went wrong while getting the company's applications");
          console.log(error);
        }
      });
  };

  const setCompanyDetailsFromResponse = (response: any) => {
    setCompanyDetails(response.data.data);
    response.data.data.applications && response.data.data.applications && setCompanyApplications(response.data.data.applications);
    response.data.data.pendingInvoiceItems && setCompanyPendingInvoiceItems(response.data.data.pendingInvoiceItems);
    response.data.data.salarySacrificeRecurringItems && setCompanySalarySacrificeRecurringItems(response.data.data.salarySacrificeRecurringItems);
    response.data.data.xeroConfiguration && setCompanyXeroConfiguration(response.data.data.xeroConfiguration);
  };

  const sendRequestToAddPaymentDetails = async (companyID: any) => {
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    return axios
      .post(
        `${dashboardApiUrl}/send-payment-details-request`,
        {
          companyID: companyID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response);
        if (response.data.success) {
          toast.success("Request sent successfully!");
          updateSelectedCompany(response.data.updatedCompany);
          return Promise.resolve();
        }
      })
      .catch((error) => {
        // If there's an error, sign the user out

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        } else {
          toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
          console.log(error);
        }
        return Promise.reject();
      });
  };

  const sendUnregisteredCompanyOnboardingInstructions = async (companyID: any) => {
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    return axios
      .post(
        `${dashboardApiUrl}/send-onboarding-instructions`,
        {
          companyID: companyID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response);
        if (response.data.success) {
          toast.success("Onboarding email sent to the company successfully!");
          return Promise.resolve();
        }
      })
      .catch((error) => {
        // If there's an error, sign the user out

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        } else {
          toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
          console.log(error);
        }
        return Promise.reject();
      });
  };

  const createContactInXero = async (companyID: any) => {
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    return axios
      .post(
        `${dashboardApiUrl}/create-xero-contact`,
        {
          companyID: companyID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response);
        if (response.data.success) {
          setCompanyDetailsFromResponse(response);

          toast.success("Company created in Xero!");
          return Promise.resolve();
        }
      })
      .catch((error) => {
        // If there's an error, sign the user out

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        } else {
          toast.error("Something went wrong with this request but you should try again, it usually works the second time around lol.", { autoClose: 15000 });
          console.log(error);
        }

      });
  };

  const issueInvoiceForCompany = async (companyID: any) => {
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningInvoicingQuery(true);
    return axios
      .post(
        `${invoicingApiUrl}/issue`,
        {
          companyID: companyID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response);
        if (response.data.success) {
          toast.success("Invoice Issued! NB: Make sure to match the Xero contact with the GoCardless mandate before sending the invoice through Xero.", { autoClose: 15000 });
          getCompanyDetails(companyID);
          setRunningInvoicingQuery(false);

          return Promise.resolve();
        }
      })
      .catch((error) => {
        // If there's an error, sign the user out
        console.log(error)
        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        } else {
          toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
          console.log(error);
        }
        setRunningInvoicingQuery(false);

        return Promise.reject();
      });
  };


  const setCompanyToManualPayment = async (companyID: any) => {
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningInvoicingQuery(true);
    return axios
      .post(
        `${paymentApiUrl}/manual`,
        {
          companyID: companyID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response);
        if (response.data.success) {
          toast.success("Company set to Manual Payment and activated - activated email sent to company.");
          setTimeout(() => { getCompanyDetails(companyID) }, 3000)
          setRunningInvoicingQuery(false);

          return Promise.resolve();
        }
      })
      .catch((error) => {
        // If there's an error, sign the user out

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        } else {
          toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
          console.log(error);
        }
        setRunningInvoicingQuery(false);

        return Promise.reject();
      });
  };


  const disburseProductsForApplicationsByCompanyID = async (companyID: any) => {
    // const token = await auth.currentUser?.getIdToken();
    // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setRunningInvoicingQuery(true);
    return axios
      .post(
        `${voucherApiUrl}/disburse-by-company`,
        {
          companyID: companyID,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          },
        }
      )
      .then((response) => {
        // Store the data in state
        console.log(response);
        if (response.data.success) {
          toast.success("Applications disbursed!");
          response.data.csvsToGenerate.forEach(({ benefitID, emails }: any) => {
            // Prepare the data for CSV
            const csvContent = Papa.unparse(emails.map((email: any) => [email]));

            // Generate the filename with current date-time in ISO format
            const filename = `${benefitID}_${DateTime.now().toISO()}.csv`;

            // Create a Blob from the CSV content
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

            // Use FileSaver to save the CSV file
            saveAs(blob, filename);
          });
          getCompanyDetails(companyID);
          setRunningInvoicingQuery(false);

          return Promise.resolve();
        }
      })
      .catch((error) => {
        // If there's an error, sign the user out

        if (error.response?.data?.message == "EXPIRED" || error.response?.data?.message == "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        } else {
          toast.error("Something went wrong with this request. Contact Marko to sort it out lol.");
          console.log(error);
        }
        setRunningInvoicingQuery(false);

        return Promise.reject();
      });
  };

  const uploadCSVApplicationsForCompany = async (companyID: any, file: File) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("companyID", companyID);

    return axios
      .post(`${dashboardApiUrl}/upload-csv-applications`, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sessionToken"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          getCompanyDetails(companyID);
          return response;
        } else {
          return Promise.reject(new Error("Processing failed"));
        }
      })
      .catch((error) => {
        if (error.response?.data?.message === "EXPIRED" || error.response?.data?.message === "INVALID") {
          console.log(error.response?.data.message);
          axios.defaults.headers.common["Authorization"] = "";
          signOut();
          localStorage.removeItem("sessionToken");
        }
        return Promise.reject(error);
      });
  };

  async function updateSelectedCompany(company: any) {
    setSelectedCompany(company);
    var temp = companies;
    temp = temp.map((c: any) => {
      return c.companyID == company.companyID ? company : c;
    });
    setCompanies(temp);
  }

  return {
    signInWithGoogle,
    signInWithEmailAndPassword,
    signOut,
    signingIn,
    getAllApplicationsData,
    getCompanies,
    runningAllApplicationsQuery,
    setRunningAllApplicationsQuery,
    runningApplicationExportQuery,
    setRunningApplicationExportQuery,
    dashboardError,
    setAllApplicationsError,
    allApplications,
    setAllApplicationsData,
    setSigningIn,
    selectedApplication,
    setSelectedApplication,
    viewApplication,
    currentUser,
    setCurrentUser,
    dashboardFilterEndDate,
    dashboardFilterStartDate,
    setAllApplicationsFilterEndDate,
    setAllApplicationsFilterStartDate,
    approveApplication,
    approvingApplication,
    setApprovingApplication,
    resendNewApplicationEmails,
    resendHRApprovalEmail,
    companies,
    companiesError,
    setCompanies,
    setCompaniesError,
    setSelectedCompany,
    selectedCompany,
    gettingCompanies,
    setGettingCompanies,
    getCompanyDetails,
    companyDetails,
    setCompanyDetails,
    companyApplications,
    companyPendingInvoiceItems,
    sendRequestToAddPaymentDetails,
    companySalarySacrificeRecurringItems,
    companyXeroConfiguration,
    setCompanyXeroConfiguration,
    setCompanySalarySacrificeRecurringItems,
    sendUnregisteredCompanyOnboardingInstructions,
    getApplicationsExport,
    getMarketingContactsExport,
    getCurrentSalarySacrificeReport,
    getPastSalarySacrificeReport,
    createContactInXero,
    issueInvoiceForCompany,
    runningInvoicingQuery,
    setRunningInvoicingQuery,
    uploadCSVApplicationsForCompany,
    disburseProductsForApplicationsByCompanyID,
    cancelApplication,
    renewApplication,
    addCompanyActivityHistory,
    getActivationsLogHistory,
    requestInProgress,
    getSFTPFileForCompany,
    sendSpecificSalarySacrificeReport,
    setCompanyToManualPayment
  };
};
