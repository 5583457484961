import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

const MonthlyPeriodSelector = ({ selectedMonth, setSelectedMonth, selectedYear, setSelectedYear }: any) => {
  useEffect(() => {
    setSelectedYear(DateTime.now().year);
  }, []);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const currentYear = DateTime.now().year;
  const startYear = 2024;
  const years = Array.from({ length: currentYear - startYear + 2 }, (_, i) => startYear + i);

  return (
    <div className="flex flex-row  gap-2 flex-wrap m-2">
      <label>
        Month:
        <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
          <option value="" disabled>
            Select month
          </option>
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>
      </label>

      <label>
        Year:
        <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
          <option value="" disabled>
            Select year
          </option>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default MonthlyPeriodSelector;
