import { createContext, useContext, Dispatch, SetStateAction } from "react";
import { useAPI } from "../hooks/useAPI";
import { useFulfillment } from "../hooks/useFulfillment";

import { DateTime } from "luxon";

// Define the shape of the context including the user and the setter function
interface AppContextType {
  currentUser: any; // Use a more specific type for your user object
  setCurrentUser: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  allApplications: any; // Use a more specific type for your user object
  setAllApplicationsData: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  runningAllApplicationsQuery: any; // Use a more specific type for your user object
  setRunningAllApplicationsQuery: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  gettingCompanies: any;
  setGettingCompanies: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  approvingApplication: any; // Use a more specific type for your user object
  setApprovingApplication: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  runningApplicationExportQuery: any; // Use a more specific type for your user object
  runningInvoicingQuery: any; // Use a more specific type for your user object
  companyXeroConfiguration: any; // Use a more specific type for your user object
  setRunningApplicationExportQuery: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  setRunningInvoicingQuery: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  setCompanyXeroConfiguration: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  signingIn: any; // Use a more specific type for your user object
  setSigningIn: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  selectedApplication: any; // Use a more specific type for your user object
  setSelectedApplication: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  selectedFulfillment: any; // Use a more specific type for yourFulfillmentt
  setSelectedFulfillment: Dispatch<SetStateAction<any>>; // Again, use a more specific type inFulfillmenty
  selectedCompany: any; // Use a more specific type for your user object
  setSelectedCompany: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  getAllApplicationsData: any;
  setCompanyToManualPayment: any;
  getApplicationsExport: any;
  getMarketingContactsExport: any;
  getActivationsLogHistory: any;
  requestInProgress: any;
  getSFTPFileForCompany: any;
  getCurrentSalarySacrificeReport: any;
  sendSpecificSalarySacrificeReport: any;
  getPastSalarySacrificeReport: any;
  getCompanies: any;
  addCompanyActivityHistory: any;
  approveApplication: any;
  resendNewApplicationEmails: any;
  sendRequestToAddPaymentDetails: any;
  resendHRApprovalEmail: any;
  renewApplication: any;
  cancelApplication: any;
  uploadCSVApplicationsForCompany: any;
  getCompanyDetails: any;
  createContactInXero: any;
  issueInvoiceForCompany: any;
  disburseProductsForApplicationsByCompanyID: any;
  companyDetails: any;
  companyApplications: any;
  companyPendingInvoiceItems: any;
  signOut: any;
  viewApplication: any;

  signInWithEmailAndPassword: any;
  signInWithGoogle: any;
  dashboardFilterStartDate: DateTime | null;
  dashboardFilterEndDate: DateTime | null;
  setAllApplicationsFilterStartDate: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  setAllApplicationsFilterEndDate: Dispatch<SetStateAction<any>>; // Again, use a more specific type instead of any
  companies: any;
  companiesError: any;
  setCompanies: Dispatch<SetStateAction<any>>;
  setCompaniesError: Dispatch<SetStateAction<any>>;
  companySalarySacrificeRecurringItems: any;
  setCompanySalarySacrificeRecurringItems: Dispatch<SetStateAction<any>>;
  sendUnregisteredCompanyOnboardingInstructions: Dispatch<SetStateAction<any>>;
  getFulfillments: any;
  fulfillments: any;
  gettingFulfillments: any;
}

const AppContext = createContext<AppContextType>({
  currentUser: null,
  setCurrentUser: () => { },
  allApplications: null,
  setAllApplicationsData: () => { },
  gettingCompanies: null,
  runningAllApplicationsQuery: null,
  setRunningAllApplicationsQuery: () => { },
  setGettingCompanies: () => { },
  runningApplicationExportQuery: null,
  runningInvoicingQuery: null,
  companyXeroConfiguration: null,
  setRunningApplicationExportQuery: () => { },
  setRunningInvoicingQuery: () => { },
  setCompanyXeroConfiguration: () => { },
  signingIn: null,
  setSigningIn: () => { },
  selectedApplication: null,
  setSelectedApplication: () => { },
  selectedFulfillment: null,
  setSelectedFulfillment: () => { },
  selectedCompany: null,
  setSelectedCompany: () => { },
  getAllApplicationsData: () => { },
  setCompanyToManualPayment: () => { },
  getApplicationsExport: () => { },
  getMarketingContactsExport: () => { },
  getActivationsLogHistory: () => { },
  requestInProgress: () => { },
  getSFTPFileForCompany: () => { },
  getCurrentSalarySacrificeReport: () => { },
  sendSpecificSalarySacrificeReport: () => { },
  getPastSalarySacrificeReport: () => { },
  getCompanies: () => { },
  addCompanyActivityHistory: () => { },
  getCompanyDetails: () => { },
  createContactInXero: () => { },
  issueInvoiceForCompany: () => { },
  disburseProductsForApplicationsByCompanyID: () => { },
  companyDetails: null,
  companyApplications: null,
  companyPendingInvoiceItems: null,
  companies: null,
  companiesError: null,
  setCompanies: () => { },
  setCompaniesError: () => { },
  approvingApplication: null,
  setApprovingApplication: () => { },
  approveApplication: () => { },
  resendNewApplicationEmails: () => { },
  sendRequestToAddPaymentDetails: () => { },
  resendHRApprovalEmail: () => { },
  renewApplication: () => { },
  cancelApplication: () => { },
  uploadCSVApplicationsForCompany: () => { },
  companySalarySacrificeRecurringItems: null,

  sendUnregisteredCompanyOnboardingInstructions: () => { },
  setCompanySalarySacrificeRecurringItems: () => { },
  signOut: () => { },
  viewApplication: () => { },

  signInWithEmailAndPassword: () => { },
  signInWithGoogle: () => { },
  dashboardFilterStartDate: null,
  setAllApplicationsFilterStartDate: () => { }, // Again, use a more specific type instead of any
  dashboardFilterEndDate: null,
  setAllApplicationsFilterEndDate: () => { }, // Again, use a more specific type instead of any
  getFulfillments: () => { }, // Again, use a more specific type instead of any
  fulfillments: null,
  gettingFulfillments: null
});

export const useUser = () => useContext(AppContext);

export const UserProvider = ({ children }: any) => {
  const {
    signInWithGoogle,
    signInWithEmailAndPassword,
    signOut,
    getAllApplicationsData,
    setCompanyToManualPayment,
    getApplicationsExport,
    getMarketingContactsExport,
    getActivationsLogHistory,
    requestInProgress,
    getSFTPFileForCompany,
    getCurrentSalarySacrificeReport,
    sendSpecificSalarySacrificeReport,
    getPastSalarySacrificeReport,
    dashboardError,
    getCompanies,
    addCompanyActivityHistory,
    getCompanyDetails,
    createContactInXero,
    issueInvoiceForCompany,
    disburseProductsForApplicationsByCompanyID,
    companyDetails,
    setAllApplicationsData,
    setAllApplicationsError,
    allApplications,
    currentUser,
    setCurrentUser,
    signingIn,
    setSigningIn,
    selectedApplication,
    setSelectedApplication,
    selectedCompany,
    setSelectedCompany,
    viewApplication,
    runningAllApplicationsQuery,
    setRunningAllApplicationsQuery,
    gettingCompanies,
    setGettingCompanies,
    runningApplicationExportQuery,
    companyXeroConfiguration,
    runningInvoicingQuery,
    resendNewApplicationEmails,
    sendRequestToAddPaymentDetails,
    resendHRApprovalEmail,
    renewApplication,
    cancelApplication,
    uploadCSVApplicationsForCompany,

    approveApplication,
    approvingApplication,
    setApprovingApplication,
    setRunningApplicationExportQuery,
    setRunningInvoicingQuery,
    setCompanyXeroConfiguration,
    dashboardFilterEndDate,
    dashboardFilterStartDate,
    setAllApplicationsFilterEndDate,
    setAllApplicationsFilterStartDate,
    companies,
    companiesError,
    setCompanies,
    setCompaniesError,
    companyApplications,
    companyPendingInvoiceItems,
    companySalarySacrificeRecurringItems,
    sendUnregisteredCompanyOnboardingInstructions,
    setCompanySalarySacrificeRecurringItems,
    // getEmissionsData,
  } = useAPI();

  const { getFulfillments,
    fulfillments,
    gettingFulfillments,
    selectedFulfillment,
    setSelectedFulfillment,
  } = useFulfillment()
  return (
    <AppContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        allApplications,
        getCompanies,
        addCompanyActivityHistory,
        getCompanyDetails,
        createContactInXero,
        issueInvoiceForCompany,
        disburseProductsForApplicationsByCompanyID,
        companyDetails,
        setAllApplicationsData,
        runningAllApplicationsQuery,
        setRunningAllApplicationsQuery,
        setGettingCompanies,
        runningApplicationExportQuery,
        companyXeroConfiguration,
        runningInvoicingQuery,
        setRunningApplicationExportQuery,
        setRunningInvoicingQuery,
        setCompanyXeroConfiguration,
        signingIn,
        gettingCompanies,
        setSigningIn,
        selectedApplication,
        selectedFulfillment,
        setSelectedApplication,
        setSelectedFulfillment,
        selectedCompany,
        setSelectedCompany,
        approveApplication,
        resendNewApplicationEmails,
        sendRequestToAddPaymentDetails,
        approvingApplication,
        resendHRApprovalEmail,
        renewApplication,
        cancelApplication,
        uploadCSVApplicationsForCompany,
        setApprovingApplication,
        viewApplication,
        getAllApplicationsData,
        setCompanyToManualPayment,
        getApplicationsExport,
        getMarketingContactsExport,
        getActivationsLogHistory,
        requestInProgress,
        getSFTPFileForCompany,
        getCurrentSalarySacrificeReport,
        sendSpecificSalarySacrificeReport,
        getPastSalarySacrificeReport,
        signOut,
        signInWithEmailAndPassword,
        signInWithGoogle,
        dashboardFilterEndDate,
        dashboardFilterStartDate,
        setAllApplicationsFilterEndDate,
        setAllApplicationsFilterStartDate,
        companies,
        companiesError,
        setCompanies,
        setCompaniesError,
        companyApplications,
        companyPendingInvoiceItems,
        companySalarySacrificeRecurringItems,
        setCompanySalarySacrificeRecurringItems,
        sendUnregisteredCompanyOnboardingInstructions,
        getFulfillments, fulfillments,
        gettingFulfillments
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
