// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig =
  process.env.REACT_APP_ENV == "DEVELOPMENT"
    ? {
        apiKey: "AIzaSyAtctnRE3Dp2Fcko1JPL3M5xHkeIdWED_Y",
        authDomain: "cyclesaver-dev.firebaseapp.com",
        projectId: "cyclesaver-dev",
        storageBucket: "cyclesaver-dev.appspot.com",
        messagingSenderId: "333234984738",
        appId: "1:333234984738:web:6a5390cf219660ac39b77b",
        measurementId: "G-PJWWMBSZJ6",
      }
    : {
        apiKey: "AIzaSyBywyfhQ7JQ5UGgHhtouj9w6VZMC-MGzJo",
        authDomain: "breez-benefits.firebaseapp.com",
        projectId: "breez-benefits",
        storageBucket: "breez-benefits.appspot.com",
        messagingSenderId: "861629720988",
        appId: "1:861629720988:web:8cb55e5ace5bb45a728059",
        measurementId: "G-C5R0T9V229",
      };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);
export { auth };
