// Login.tsx
import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput"; // Make sure the path matches your file structure
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import Sidebar from "../../components/Sidebar";
import ResponsiveAppBar from "../../components/Navbar";
import Toolbar from "@mui/material/Toolbar";
import StatCard from "../../components/StatCard";
import { BarChart } from "../../components/charts/BarChart";
import { PieChart } from "../../components/charts/PieChart";
import { useUser } from "../../context/AppContext";
import { colors, Table, TableCell, TableRow } from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { DocusealForm } from "@docuseal/react";
import Backdrop from "@mui/material/Backdrop";

const ViewApplication = () => {
  const { selectedApplication, setSelectedApplication, resendNewApplicationEmails, resendHRApprovalEmail } = useUser();

  const [initialising, setInitialising] = useState(true);
  const [showContract, setShowContract] = useState(false);

  useEffect(() => {
    if (initialising) {
      setInitialising(false);
      // getEmissionsData();
      if (!selectedApplication) {
        const app = JSON.parse(localStorage.getItem("selectedApplication") ?? "");
        if (app) {
          setSelectedApplication(app);
        }
      }
      console.log(selectedApplication);
    }
  }, []);

  var titleTextStyle = {
    color: "#555", // You can set the title color
    fontName: "Montserrat",
    fontSize: 22, // Adjust as needed
    bold: true, // true or false
    italic: false, // true or false
  };

  var smallTitleTextStyle = {
    ...titleTextStyle,
    fontSize: 16, // Adjust as needed
  };
  return (
    <div className="h-screen flex-col flex-grow bg-white">
      <ResponsiveAppBar />
      <div className="flex-grow flex  w-full h-full pt-1 bg-white ">
        <div className="m-10 w-full">
          <div className="flex flex-col justify-between items-end gap-2">
            <h2>Application {selectedApplication?.id}</h2>
            <div>
              <h2>Status: {selectedApplication?.status}</h2>
            </div>
          </div>
          <div className="flex flex-col border bg-lightgrey rounded-lg p-4 px-6 gap-2">
            {" "}
            <h2>ACTIONS</h2>
            <div className="flex flex-row  gap-6 ">
              {!selectedApplication?.company.active && (
                <button className="font-montserrat font-semibold max-w-[300px]" onClick={resendHRApprovalEmail}>
                  <h4 className="text-darkgrey text-center font-semibold">Resend HR approval email</h4>
                </button>
              )}
              {selectedApplication?.status == "PROCESSING" && (
                <button
                  className="font-montserrat font-medium bg-darkgrey text-secondary max-w-[300px]"
                  onClick={resendNewApplicationEmails}
                >
                  <h4 className="text-secondary text-center font-semibold">Resend all emails</h4>
                </button>
              )}{" "}
            </div>
          </div>
          {selectedApplication?.status == "AWAITING_EMPLOYER_SIGNATURE" && (
            <div className="bg-red/20 p-8 rounded-xl m-2 gap-1 flex flex-col">
              <h2 className="text-red ">ACTION REQUIRED!</h2>
              <h5 className="text-primary font-medium">
                Please sign the CycleSaver contract to continue with fulfilment of this application.
              </h5>
              <div className="mt-2">
                <button
                  onClick={() => {
                    setShowContract(true);
                  }}
                  className="flex flex-row items-center justify-center  bg-primary"
                >
                  <h3 className="text-white font-medium text-base">Click here to sign this contract</h3>
                </button>
              </div>
            </div>
          )}
          {selectedApplication?.status != "AWAITING_EMPLOYER_SIGNATURE" &&
            selectedApplication?.status != "PROCESSING" && (
              <div className="bg-primary/40 p-8 rounded-xl m-2 gap-1 flex flex-col">
                <h2 className="text-red ">ACTION REQUIRED!</h2>
                <h5 className="text-primary font-medium">
                  Please sign the CycleSaver contract to continue with fulfilment of this application.
                </h5>
                <div className="mt-2">
                  <button
                    onClick={() => {
                      setShowContract(true);
                    }}
                    className="flex flex-row items-center justify-center  bg-primary"
                  >
                    <h3 className="text-white font-medium text-base">Click here to sign this contract</h3>
                  </button>
                </div>
              </div>
            )}
          <Table style={{ width: "100%" }}>
            <TableRow>
              <TableCell>
                <h3>Created</h3>
              </TableCell>
              <TableCell>
                <p>
                  {DateTime.fromISO(selectedApplication?.creationDateTime).toFormat("yyyy-MM-dd HH:mm:ss") +
                    " (" +
                    Math.round(
                      DateTime.now().diff(DateTime.fromISO(selectedApplication?.creationDateTime), "days").days
                    ) +
                    " days ago)"}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h3>Company</h3>
              </TableCell>
              <TableCell>
                <Table>
                  <TableRow>
                    <TableCell>
                      <p>Company Status</p>
                    </TableCell>
                    <TableCell>
                      <p
                        className={`${
                          selectedApplication?.company?.active != true ? `text-red font-bold` : `text-green-600`
                        } font-bold`}
                      >
                        {selectedApplication?.company?.active ? "ACTIVE" : "INACTIVE"}
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>Name</p>
                    </TableCell>
                    <TableCell>
                      <p>{selectedApplication?.company?.name}</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>Address</p>
                    </TableCell>
                    <TableCell>
                      <p>{selectedApplication?.company?.address}</p>
                    </TableCell>
                  </TableRow>
                </Table>
                <p className="mt-5 font-medium">HR Contact Details:</p>
                {selectedApplication?.company?.hrContacts.map((contact: any, index: number) => {
                  return (
                    <Table>
                      <p className="mt-2">Contact {index + 1}</p>
                      <TableRow>
                        <TableCell>
                          <p>Name: </p>
                        </TableCell>
                        <TableCell>
                          <p>{contact.name}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p>Phone: </p>
                        </TableCell>
                        <TableCell>
                          <div
                            className="flex flex-row gap-3"
                            onClick={() => {
                              navigator.clipboard.writeText(contact.phone);
                              toast("Copied!", { autoClose: 1000, type: "info" });
                            }}
                          >
                            <p>
                              {" "}
                              <a href={`tel:${contact.phone}`}>{contact.phone}</a>{" "}
                            </p>
                            <img
                              src={require("../../assets/copy.png")}
                              className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                            />
                          </div>{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p>Email: </p>
                        </TableCell>
                        <TableCell>
                          <div
                            className="flex flex-row gap-3"
                            onClick={() => {
                              navigator.clipboard.writeText(contact.email);
                              toast("Copied!", { autoClose: 1000, type: "info" });
                            }}
                          >
                            <p> {contact.email}</p>
                            <img
                              src={require("../../assets/copy.png")}
                              className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </Table>
                  );
                }) ?? <p className="font-medium italic mt-2">No HR contact details found.</p>}
                <p className="mt-5 font-medium">Finance Contact Details:</p>
                {selectedApplication?.company?.financeContacts?.map((contact: any, index: number) => {
                  return (
                    <Table>
                      <p className="mt-2">Contact {index + 1}</p>

                      <TableRow>
                        <TableCell>
                          <p>Name: </p>
                        </TableCell>
                        <TableCell>
                          <p>{contact.name}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p>Phone: </p>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <div
                            className="flex flex-row gap-3"
                            onClick={() => {
                              navigator.clipboard.writeText(contact.phone);
                              toast("Copied!", { autoClose: 1000, type: "info" });
                            }}
                          >
                            <p>
                              {" "}
                              <a href={`tel:${contact.phone}`}>{contact.phone}</a>{" "}
                            </p>
                            <img
                              src={require("../../assets/copy.png")}
                              className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p>Email: </p>
                        </TableCell>
                        <TableCell>
                          <div
                            className="flex flex-row gap-3"
                            onClick={() => {
                              navigator.clipboard.writeText(contact.email);
                              toast("Copied!", { autoClose: 1000, type: "info" });
                            }}
                          >
                            <p>
                              {" "}
                              <a href={`tel:${contact.email}`}>{contact.email}</a>{" "}
                            </p>
                            <img
                              src={require("../../assets/copy.png")}
                              className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                            />
                          </div>
                          <p>{contact.email}</p>
                        </TableCell>
                      </TableRow>
                    </Table>
                  );
                }) ?? <p className="font-medium italic mt-2">No finance contact details found.</p>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h3>Employee</h3>
              </TableCell>
              <TableCell>
                <Table>
                  <TableRow>
                    <TableCell>
                      <p>Name: </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">
                        {selectedApplication?.employee.firstName} {selectedApplication?.employee.surname}
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>Phone: </p>
                    </TableCell>
                    <TableCell>
                      <div
                        className="flex flex-row gap-3"
                        onClick={() => {
                          navigator.clipboard.writeText(selectedApplication?.employee.phone);
                          toast("Copied!", { autoClose: 1000, type: "info" });
                        }}
                      >
                        <p>
                          {" "}
                          <a href={`tel:${selectedApplication?.employee.phone}`}>
                            {selectedApplication?.employee.phone}
                          </a>{" "}
                        </p>
                        <img
                          src={require("../../assets/copy.png")}
                          className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>Email: </p>
                    </TableCell>
                    <TableCell>
                      <div
                        className="flex flex-row gap-3"
                        onClick={() => {
                          navigator.clipboard.writeText(selectedApplication?.employee.email);
                          toast("Copied!", { autoClose: 1000, type: "info" });
                        }}
                      >
                        <p>{selectedApplication?.employee.email}</p>
                        <img
                          src={require("../../assets/copy.png")}
                          className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>Employee ID: </p>
                    </TableCell>
                    <TableCell>
                      <p>{selectedApplication?.employee.employeeId}</p>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h3>Benefit</h3>
              </TableCell>
              <TableCell>
                <div className="flex flex-row gap-2">
                  <img src={selectedApplication?.benefit.imageURL} className="w-12 h-12 rounded-full" />
                  <p>{selectedApplication?.benefit.name}</p>
                </div>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
      {selectedApplication && showContract && (
        <Backdrop
          className="h-screen w-screen relative p-6"
          open={showContract}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <div className="h-[95%] bg-white relative rounded-3xl py-6">
            <div
              className="absolute top-5 left-5 z-10 bg-white/90 flex flex-row items-center justify-center gap-2"
              onClick={() => setShowContract(false)}
            >
              <img src={require("../../assets/close.png")} className="w-8 h-8" />
              <p>Close</p>
            </div>

            <div className="overflow-y-scroll h-full px-5 py-4">
              <DocusealForm
                src={`https://docuseal.co/d/${
                  selectedApplication &&
                  selectedApplication.docusealSubmission?.data?.find((d: any) => d.role == "Employer").slug
                }`}
                email="info@cyclesaver.co.uk"
                onComplete={(data) => {
                  console.log(data);

                  toast.success("Thank you! Your contract has been signed. Please download this contract.");
                }}
              />
            </div>
          </div>
        </Backdrop>
      )}
    </div>
  );
};

export default ViewApplication;
