// Login.tsx
import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput"; // Make sure the path matches your file structure
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import Sidebar from "../../components/Sidebar";
import ResponsiveAppBar from "../../components/Navbar";
import Toolbar from "@mui/material/Toolbar";
import StatCard from "../../components/StatCard";
import { BarChart } from "../../components/charts/BarChart";
import { PieChart } from "../../components/charts/PieChart";
import { useUser } from "../../context/AppContext";
import { colors, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { DocusealForm } from "@docuseal/react";
import Backdrop from "@mui/material/Backdrop";
import DynamicTable, { renderContactItem, renderActiveIndicator } from "../../components/DynamicTable/DynamicTable";
import { useNavigate } from "react-router-dom";
import { mapDebitOrderStatus } from "../../util/util";

const Companies = () => {
  const {
    selectedApplication,
    resendNewApplicationEmails,
    resendHRApprovalEmail,
    getCompanies,
    companies,
    companiesError,
    setSelectedCompany,
    selectedCompany,
    gettingCompanies,
  } = useUser();
  const navigate = useNavigate();

  const [initialising, setInitialising] = useState(true);
  const [showContract, setShowContract] = useState(false);

  useEffect(() => {
    if (!companies) {
      setInitialising(false);
      getCompanies();
      console.log(selectedApplication);
    }
  }, []);

  useEffect(() => {
    console.log("Got compniaes");
  }, [companies]);

  var titleTextStyle = {
    color: "#555", // You can set the title color
    fontName: "Montserrat",
    fontSize: 22, // Adjust as needed
    bold: true, // true or false
    italic: false, // true or false
  };

  var smallTitleTextStyle = {
    ...titleTextStyle,
    fontSize: 16, // Adjust as needed
  };
  return (
    <div className="min-h-screen flex-col flex-grow bg-white p-4">
      {/* <ResponsiveAppBar /> */}
      <h2 className="ml-2">COMPANIES</h2>

      <div className="flex-grow flex w-full h-full pt-1 bg-white ">
        {/* <div className="m-10 w-full"> */}

        <DynamicTable
          tableId="CompaniesTable"
          cols={["ID", "Date Registered", "Status", "Name", "Address", "#Apps"]}
          filterableColumns={["Status", "#Apps"]}
          showExport={true}
          rows={companies?.map((company: any) => {
            return [
              company.companyID,
              company.registrationDateTime,
              renderActiveIndicator(company),
              company.name,
              company.addressObject ? company.addressObject.postcode : company.address,

              company.countOfApplications ?? 0
            ];
          })}
          onRowClicked={(companyID: any) => {
            const selected = companies.find((c: any) => c.companyID == companyID);
            console.log(selected);
            // localStorage.setItem("selectedCompanyID", companies[index].companyID);
            setSelectedCompany(selected);
            navigate(`/companies/${companyID}`);
          }}
          idIndexInColumns={0}
          showID={true}
          // classNames={["", "", "", "", "", ""]}
          loading={gettingCompanies}
          rowExpansionComponents={companies?.map((company: any) => {
            return <div className="flex flex-row flex-wrap gap-2 ">
              <div className="border border-lightgrey rounded-lg p-2">
                {company.hrContacts?.map((contact: any) => renderContactItem(contact, "HR"))}

              </div>
              <div className="border border-lightgrey rounded-lg p-2">
                {company.financeContacts?.map((contact: any) => renderContactItem(contact, "FIN"))}

              </div>
              {/* <div className="border border-lightgrey rounded-lg p-2">
                <p className="font-bold text-base">Salary Sacrifice Terms</p>
                {application.salarySacrificeTerms?.numberOfSacrifices > 0 ? <p className="">{application.salarySacrificeTerms?.numberOfSacrifices} months</p> : <p>Ongoing until cancelled</p>}

              </div>
              {application.activityLog && application.activityLog.length > 0 &&
                <div className="border border-lightgrey rounded-lg p-2 max-w-[300px]">
                  <p className="font-bold text-base">Latest activity</p>
                  <p className="text-sm">{application.activityLog[application.activityLog.length - 1].action}</p>

                </div>
              } */}
              {company.activityLog && company.activityLog.length > 0 &&
                <div className="border border-lightgrey rounded-lg p-2 max-w-[300px]">
                  <p className="font-bold text-xs">Latest activity</p>
                  <p className="text-xs">{company.activityLog[company.activityLog.length - 1].action}</p>

                </div>
              }
            </div>
          })}
        />
      </div>
    </div>
  );
};

export default Companies;
