import React, { useEffect } from "react";
import ResponsiveAppBar from "../Navbar";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "../../context/AppContext";

const Layout = React.memo(() => {
    const { currentUser } = useUser();
    const location = useLocation();

    // If we're on the login page, only show the Outlet
    if (location.pathname === '/login' || location.pathname === '/') {
        return <Outlet />;
    }

    return (
        <div className="min-h-screen flex flex-col">
            <ResponsiveAppBar key={currentUser ? 'logged-in' : 'logged-out'} />
            <main className={`flex-grow bg-white pt-16`}>
                <Outlet />
            </main>
        </div>
    );
});

export default Layout;

