// src/hooks/useFulfillment.js

import { useEffect, useState } from "react";

import axios from "axios";
import { DateTime } from "luxon";

export const useFulfillment = () => {
    const [fulfillments, setFulfillments] = useState<any[]>();
    const [gettingFulfillments, setGettingFulfillments] = useState<boolean>(false);
    const [selectedFulfillment, setSelectedFulfillment] = useState<any>();
    const fulfillmentApiUrl = process.env.REACT_APP_FUNCTIONS_URL + "/api/fulfillment";

    useEffect(() => {
        if (!fulfillments) {
            getFulfillments(null, null)
        }
    }, [])

    const getFulfillments = async (companyID: string | null, date: string | null) => {
        setGettingFulfillments(true);


        axios.post(`${fulfillmentApiUrl}/all`, {
            date: date ?? DateTime.now().toISO(),
            companyID: companyID ?? null,
        },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("sessionToken")
                },
            }
        )
            .then((response) => {
                setFulfillments(response.data.data);
                setGettingFulfillments(false);
                console.log(response.data.data)

            })
            .catch((error) => {
                setGettingFulfillments(false);
                console.log(error.response?.data.message);
            });
    };

    return {
        fulfillments,
        gettingFulfillments,
        getFulfillments,
        setSelectedFulfillment,
        selectedFulfillment
    }
}