import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import { Button } from "@mui/material";
// Define the props expected by the DateRangePicker component
interface DateRangePickerProps {
  startDate: DateTime | null;
  setStartDate: (date: DateTime) => void;
  endDate: DateTime | null;
  setEndDate: (date: DateTime) => void;
  onDatesSelected: () => void;
  mini?: boolean
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate = DateTime.now().startOf("year"),
  setStartDate,
  endDate = DateTime.now().endOf("year"),
  setEndDate,
  onDatesSelected,
  mini = false
}) => {
  const updatePeriod = (period: string) => {
    let newStartDate: DateTime;

    switch (period) {
      case "MTD":
        newStartDate = DateTime.now().startOf("month");
        break;
      case "1W":
        newStartDate = DateTime.now().minus({ weeks: 1 });
        break;
      case "1M":
        newStartDate = DateTime.now().minus({ months: 1 });
        break;
      case "3M":
        newStartDate = DateTime.now().minus({ months: 3 });
        break;
      case "6M":
        newStartDate = DateTime.now().minus({ months: 6 });
        break;
      default:
        newStartDate = DateTime.now();
    }

    // Update state with the new start and end dates
    setStartDate(newStartDate);
    setEndDate(DateTime.now());

    onDatesSelected();
  };
  return (
    <div style={{ display: "flex", gap: "20px", marginTop: "10px", flexWrap: "wrap" }}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue: any) => {
            setStartDate(newValue);
          }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          minDate={startDate ?? undefined} // Fallback to undefined if startDate is null
          onChange={(newValue: any) => {
            setEndDate(newValue);
          }}
        />
      </LocalizationProvider>

      <div className="flex flex-row gap-1 flex-wrap">
        <Button
          style={{
            border: "1px solid #ddd",
            width: "100px",
            fontFamily: "Montserrat",
            fontWeight: "bolder",
          }}
          onClick={onDatesSelected}
        >
          Apply
        </Button>
        {!mini &&
          <>
            <Button
              style={styles.periodPickerButtonStyle}
              onClick={() => {
                updatePeriod("MTD");
              }}
            >
              MTD
            </Button>
            <Button
              style={styles.periodPickerButtonStyle}
              onClick={() => {
                updatePeriod("1W");
              }}
            >
              1W
            </Button>
            <Button
              style={styles.periodPickerButtonStyle}
              onClick={() => {
                updatePeriod("1M");
              }}
            >
              1M
            </Button>
            <Button
              style={styles.periodPickerButtonStyle}
              onClick={() => {
                updatePeriod("3M");
              }}
            >
              3M
            </Button>
            <Button
              style={styles.periodPickerButtonStyle}
              onClick={() => {
                updatePeriod("6M");
              }}
            >
              6M
            </Button>
          </>
        }

      </div>
    </div>
  );
};

const styles = {
  periodPickerButtonStyle: {
    border: "1px solid #ddd",
    width: "50px",

    fontFamily: "Montserrat",
  },
};

export default DateRangePicker;
