import React from "react";
import TextField from "@mui/material/TextField";

interface SearchBoxProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const SearchBox: React.FC<SearchBoxProps> = ({ value, onChange, placeholder }) => {
  return (
    <div className="p-2">
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder={placeholder || "Type to search the table..."}
        fullWidth
      />
    </div>
  );
};

export default SearchBox;
