import React, { useEffect, useState } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, CircularProgress } from "@mui/material";
import { DateTime } from "luxon";
import { useUser } from "../../context/AppContext";
import ResponsiveAppBar from "../../components/Navbar";
import { toast } from "react-toastify";
import DynamicTable from "../../components/DynamicTable/DynamicTable";

const AllApplications = () => {
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" | undefined }>({
    key: "",
    direction: undefined,
  });
  const {
    getAllApplicationsData,
    runningAllApplicationsQuery,
    allApplications,
    viewApplication,
    approveApplication,
    getApplicationsExport,
    getActivationsLogHistory,
    runningApplicationExportQuery,
    getMarketingContactsExport
  } = useUser();
  const [initialising, setInitialising] = useState(true);
  useEffect(() => {
    console.log("Running dashboard query changed to " + runningAllApplicationsQuery);
  }, [runningAllApplicationsQuery]);

  useEffect(() => {
    setSortConfig({ key: "creationDateTime", direction: "desc" });
    requestSort("creationDateTime");
  }, [allApplications]);

  useEffect(() => {
    if (initialising) {
      setInitialising(false);
      getAllApplicationsData();
    }
  }, []);

  useEffect(() => {
    console.log("Changed to ", sortConfig);
  }, [sortConfig]);

  const requestSort = (key: string | null) => {
    if (key && allApplications) {
      let direction: "asc" | "desc" = "asc";
      if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key, direction });
      const sorted = [...allApplications].sort((a, b) => {
        const valueA = getValue(a, key)?.toString()?.toLowerCase();
        const valueB = getValue(b, key)?.toString()?.toLowerCase();
        if (valueA < valueB) {
          return direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedData(sorted);
    }
  };

  const getValue = (object: any, path: string) => {
    const keys = path.split(".");
    let value = object;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return undefined;
      }
    }
    return value;
  };

  const getStatusStyles = (status: string): string => {
    const styleMap: Record<string, string> = {
      PROCESSING: "bg-orange-100 border-orange-400",
      ACTIVE: "bg-green-100 border-green-400",
      COMPLETED: "bg-green-100 border-green-400",
      INFORMATION_REQUIRED: "bg-yellow-100 border-yellow-400",
      AWAITING_EMPLOYER_SIGNATURE: "bg-blue-100 border-blue-400",
      DECLINED: "bg-red/30 border-red",
      AWAITING_ACTIVATION: "bg-purple-100 border-purple-400",
      CANCELLED: "bg-red/30 border-red"
    };

    return styleMap[status] || "";
  };

  return (
    <div className="min-h-screen flex-col  bg-white">
      <div className=" flex w-full min-h-screen bg-white ">
        {/* <Sidebar /> */}
        <div className="flex flex-col px-3 py-3 md:p-6 w-full ">
          <h2 className="ml-2">APPLICATIONS</h2>

          <div className="flex flex-col border-2 border-primary rounded-lg p-4 px-6 gap-2 my-4">
            {" "}
            <p className="font-semibold">ACTIONS</p>
            <div className="flex flex-col w-full md:flex-row  items-start rounded-md gap-2 ">
              <button
                disabled={runningApplicationExportQuery}
                className="flex flex-row gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md"
                onClick={() => {
                  getApplicationsExport("VOUCHER");
                }}
              >
                {runningApplicationExportQuery ? (
                  <CircularProgress size={20} style={{ color: "#86eb85" }} />
                ) : (
                  <img src={require("../../assets/download.png")} className="w-6 h-6" />
                )}
                <p className="">Export all applications</p>
              </button>
              <button
                disabled={runningApplicationExportQuery}
                className="flex flex-row gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md"
                onClick={() => {
                  getActivationsLogHistory();
                }}
              >
                {runningApplicationExportQuery ? (
                  <CircularProgress size={20} style={{ color: "#86eb85" }} />
                ) : (
                  <img src={require("../../assets/download.png")} className="w-6 h-6" />
                )}
                <p className="">Export Activation Log</p>

              </button>
              <button
                disabled={runningApplicationExportQuery}
                className="flex flex-row gap-2 items-center justify-center  bg-white  px-4 py-2  rounded-md"
                onClick={() => {
                  getMarketingContactsExport();
                }}
              >
                {runningApplicationExportQuery ? (
                  <CircularProgress size={20} style={{ color: "#86eb85" }} />
                ) : (
                  <img src={require("../../assets/download.png")} className="w-6 h-6" />
                )}
                <p className="">Export All Contacts</p>

              </button>
            </div>

          </div>
          <DynamicTable
            cols={["Date", "Status", "ID", "Benefit", "Employee", "Company"]}
            filterableColumns={["Date", "Status", "Benefit", "Company"]}
            showExport={true}
            tableId="AllApplications"
            rows={allApplications?.map((application: any) => [
              <p>{application.creationDateTime}</p>,
              <div className="flex flex-row items-start justify-start">
                <p className={`flex-wrap text-wrap p-1 px-2 border-2 rounded-lg ${getStatusStyles(application.status)}`}>
                  <p>{application.status.replaceAll("_", " ").split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase()).join(' ')}</p>
                </p>
              </div>,
              <p>{application.id}</p>,
              <div className="flex flex-row items-center justify-start gap-3 mx-1">
                <img src={application.benefit.imageURL} className="w-8 h-8 rounded-md" />
                <p>{application.benefit.name}</p>
              </div>,

              <p>{application.employee.firstName} {application.employee.surname}</p>,
              , <div className="flex flex-col">
                <p>{application.company.name}</p>
                {application.companyAutomaticallyReassigned &&
                  <div className="flex flex-row">
                    <img src="/exchange.png" className="w-4 h-4" />
                    <p className="pt-1">{application.archivedCompanyID}</p>
                  </div>
                }
              </div>,
            ])}
            onRowClicked={(applicationID: string) => {
              console.log(applicationID);
              console.log(allApplications?.filter((a: any) => a.id == applicationID)[0]);
              viewApplication(allApplications?.filter((a: any) => a.id == applicationID)[0]);
            }}
            loading={runningAllApplicationsQuery}
            idIndexInColumns={2}
            showID={true}
            // rowClasses={allApplications?.map((app: any) => {

            //   return null
            // })}
            rowExpansionComponents={allApplications?.map((application: any) => {
              return <div className="flex flex-row flex-wrap gap-2 ">
                <div className="border border-lightgrey rounded-lg p-2">
                  <p className="font-bold text-base">Employee</p>
                  <p className="">{`${application.employee.firstName} ${application.employee.surname}`}</p>
                  <div
                    className="flex flex-row gap-3"
                    onClick={() => {
                      navigator.clipboard.writeText(application?.employee.email);
                      toast("Copied!", { autoClose: 1000, type: "info" });
                    }}
                  >
                    <p>{application?.employee.email}</p>
                    <img
                      src={require("../../assets/copy.png")}
                      className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                    />
                  </div>
                  <div
                    className="flex flex-row gap-3"
                    onClick={() => {
                      navigator.clipboard.writeText(application?.employee.phone);
                      toast("Copied!", { autoClose: 1000, type: "info" });
                    }}
                  >
                    <p>
                      {" "}
                      <a href={`tel:${application?.employee.phone}`}>{application?.employee.phone}</a>{" "}
                    </p>
                    <img
                      src={require("../../assets/copy.png")}
                      className="w-4 h-4 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
                    />
                  </div>
                </div>
                <div className="border border-lightgrey rounded-lg p-2">
                  <p className="font-bold text-base">Salary Sacrifice Terms</p>
                  {application.salarySacrificeTerms?.numberOfSacrifices > 0 ? <p className="">{application.salarySacrificeTerms?.numberOfSacrifices} months</p> : <p>Ongoing until cancelled</p>}

                </div>
                {application.activityLog && application.activityLog.length > 0 &&
                  <div className="border border-lightgrey rounded-lg p-2 max-w-[300px]">
                    <p className="font-bold text-base">Latest activity</p>
                    <p className="text-sm">{application.activityLog[application.activityLog.length - 1].action}</p>

                  </div>
                }
              </div>
            })}
          />
        </div>
      </div>
    </div >
  );
};

export default AllApplications;
