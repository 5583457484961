const mapApplicationStatus = (status: string): string => {
  switch (status) {
    case "PROCESSING":
      return "Processing";
    case "AWAITING_EMPLOYEE_SIGNATURE":
      return "Awaiting Employee Signature";
    case "AWAITING_EMPLOYER_SIGNATURE":
      return "Awaiting Employer Signature";
    case "AWAITING_ACTIVATION":
      return "Awaiting Activation";
    case "ACTIVE":
      return "Active";
    case "COMPLETED":
      return "Completed";
    case "CANCELLED":
      return "Cancelled";
    case "PENDING_PRODUCT_CHANGE":
      return "Pending Product Change";
    default:
      return status;
  }
};

const mapDebitOrderStatus = (status: string): string => {
  switch (status) {
    case "fulfilled":
      return "Fulfilled";
    case "pending":
      return "Pending";

    default:
      return status;
  }
};

const mapCompanyNextStatus = (status: string): string => {
  switch (status) {
    case "CREATED":
      return "Add Payment Details";
    case "PAYMENT_REQUEST_SENT":
      return "Add Payment Details";
    case "MANDATE_COMPLETED":
      return "Awaiting Employer Signature";
    
    default:
      return status;
  }
};

const mapCompanyStatus = (status: string): string => {
  switch (status) {
    case "CREATED":
      return "Created";
    case "PAYMENT_REQUEST_SENT":
      return "Awaiting Employee Signature";
    case "MANDATE_COMPLETED":
      return "Awaiting Employer Signature";
    
    default:
      return status;
  }
};

export { mapApplicationStatus, mapDebitOrderStatus };
